(function () {
    'use strict';

    angular.module('informaApp')
        .component('diseaseViewFilter', {
            templateUrl: 'components/disease-view/disease-view-filter/template.html',
            controller: ['$element', DiseaseViewController],
            bindings: {
                title: '@',
                diseases: '<',
                activeDiseaseId: '<',
                onSelectDisease: '<',
                height: '<'
            }
        });

    function DiseaseViewController($element) {
        var vm = this;

        this.$onInit = function () {
            makeElementSticky();

            makeSelect2();
        };

        this.$onChanges = function (changes) {
            if (changes.activeDiseaseId) {
                var select = getSelect();

                select.val(changes.activeDiseaseId.currentValue);
                select.trigger('change');
            }
        };

        function makeSelect2() {
            var select = getSelect();

            select.select2({
                width: '100%',
                placeholder: 'Select disease'
            });

            select.on('select2:select', function (a) {
                if (vm.onSelectDisease) {
                    vm.onSelectDisease(select.val())
                }
            });
        }

        function getSelect() {
            return $element.find('select.select');
        }

        function makeElementSticky() {
            var targetElement = $element.find('.disease-view-filter');

            targetElement.affix({
                offset: {
                    top: targetElement.offset().top
                }
            });
        }
    }
})();